import React from "react"
import ReactDOM from "react-dom"

import styles from "./stylesheet.scss"

if (process.env.NODE_ENV !== "production")
  console.log("Looks like we are in development mode!")

const App = () => (
  <div className={styles.container}>
    <h1>
      <span>étcheby</span>
    </h1>

    <div className={styles.buy}>
      <a
        href="https://etcheby.bandcamp.com/"
        title="listen on bandcamp"
        rel="noopener"
        target="_blank"
      >
        <img alt="icon" src="img/icons/bandcamp.png" />
      </a>
      <a
        title="listen on spotify"
        rel="noopener"
        target="_blank"
        href="https://open.spotify.com/album/1mtNa7PIG2GC99kzO4Uldv"
      >
        <img alt="icon" src="img/icons/spotify.png" />
      </a>
      <a
        title="listen on apple music"
        rel="noopener"
        target="_blank"
        href="https://music.apple.com/us/album/pas-avant-une-couple-de-jours/1514974011?uo=4"
      >
        <img alt="icon" src="img/icons/apple.png" />
      </a>
      <a
        title="listen on itunes"
        rel="noopener"
        target="_blank"
        href="https://music.apple.com/us/album/pas-avant-une-couple-de-jours/1514974011?uo=4&app=itunes&at=1001lry3&ct=dashboard"
      >
        <img alt="icon" src="img/icons/itunes.png" />
      </a>
      {/*  <a
        title="listen on deezer"
        rel="noopener"
        target="_blank"
        href="https://www.deezer.com/fr/album/88503502"
      >
        <img alt="icon" src="img/icons/deezer.png" />
      </a>*/}
      <a
        title="listen on google play"
        rel="noopener"
        target="_blank"
        href="https://play.google.com/store/music/album/%C3%A9tcheby_pas_avant_une_couple_de_jours?id=Btcqhwposjj4xxf5qdg2zagigta"
      >
        <img alt="icon" src="img/icons/google-play.png" />
      </a>
      {/*  <a
        title="listen on amazon"
        rel="noopener"
        target="_blank"
        href="https://www.amazon.com/Cent-trente-deux-Mat%C3%A9o-amigxs-express/dp/B07P413P46/ref=sr_1_1?keywords=mat%C3%A9o+%2B+amigxs+express&qid=1551816831&s=dmusic&sr=1-1-spell"
      >
        <img alt="icon" src="img/icons/amazon.png" />
      </a>*/}
    </div>

    <div className={styles.videocontainer}>
      <video autoPlay muted loop className={styles.bg}>
        <source src="img/mateo_bg_2.mp4" type="video/mp4" />
      </video>
    </div>
    <footer>
      etcheby &copy; 2020 Montreal / website by
      <a
        href="https://www.pinkbeton.com"
        style={{
          marginLeft: "1vmin",
          textDecoration: "none"
        }}
        target="_blank"
      >
        &#9751; pinkbeton
      </a>{" "}
      +{" "}
      <a
        href="https://www.flaticon.com/authors/freepik"
        target="_blank"
        rel="noopener"
      >
        freekpik icons
      </a>
    </footer>
  </div>
)

export default App

ReactDOM.render(<App />, document.getElementById("app"))

if (process.env.NODE_ENV !== "production")
  if (module.hot) {
    module.hot.accept()
    console.log("activated hot module")
  }
